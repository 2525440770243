@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after {
    position: absolute;
    top: 0;
    content: '';
  }
  
  .jimu-primary-loading:before {
    left: -19.992px;
  }
  
  .jimu-primary-loading:after {
    left: 19.992px;
    -webkit-animation-delay: 0.32s !important;
    animation-delay: 0.32s !important;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after,
  .jimu-primary-loading {
    background: #076fe5;
    -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    width: 13.6px;
    height: 32px;
  }
  
  .jimu-primary-loading {
    text-indent: -9999em;
    margin: auto;
    position: absolute;
    right: calc(50% - 6.8px);
    top: calc(50% - 16px);
    -webkit-animation-delay: 0.16s !important;
    animation-delay: 0.16s !important;
  }
  
  @-webkit-keyframes loading-keys-app-loading {
  
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 #076fe5;
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px #076fe5;
      height: 40px;
    }
  }
  
  @keyframes loading-keys-app-loading {
  
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 #076fe5;
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px #076fe5;
      height: 40px;
    }
  }

  /* Customize the width of the scrollbar */
*::-webkit-scrollbar {
  width: 3px; /* Set the width of the vertical scrollbar */
  height: 3px; /* Set the height of the horizontal scrollbar */
}

/* Customize the track of the scrollbar */
*::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Background color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Customize the thumb of the scrollbar */
*::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

/* Add a hover effect to the thumb */
*::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when the thumb is hovered */
}